.services {
  margin-top: 9vh;
  /* z-index: 1; */
  overflow: hidden;
}

.services h1{
  text-align: center;
  font-family: 'Outfit', sans-serif;
}

.services h2 {
  font-family: 'Outfit', sans-serif;
}

.services h2 {
  margin: 0 0 0.5em 0;
}

.services .body {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 300;
  font-size: 1.1em;
  margin-bottom: 2em;
}

.categories {
  margin-top: 5vh;
}

.services-image {
  width: 35vw;
  margin: 2vh 0 2vh 0;
}

.services .background {
  width: 100%;
  background-color: rgba(246, 246, 246, 1);
}

.printing-service-category .background {
  background-color: white;
}

.categories .content {
  display: flex;
  justify-content: space-between;
  margin: 0 10% 0 10%;
}

.services .text-side, .services .image-side {
  font-size: 1.15em;
}

.services .image-side {
  display: flex;
  flex-direction: column;
  margin-top: 2em;
}

.read-more {
  color: rgba(255, 102, 0, 1);
}

.read-more:hover {
  cursor: pointer;
}

.printing-service-category .text-side {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 4vh;
}

.printing-service-category .image-side {
  margin-top: 0;
  justify-content: center;
}

.categories .text-side {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 900px) {
  .services .content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .printing-service-category .content {
    flex-direction: column-reverse;
  }

  .services .text-side {
    width: 100%;
  }

  .services-image {
    margin-top: 0vh
  }
}