.navbar {
  display: flex;
  margin-top: 3vh;
  margin-left: 5vw;
  margin-right: 5vw;
  justify-content: space-between;
  align-items: center;
  font-size: 1.15em;
}

.navbar img {
  width: 11vw;
}

.desktop-list {
  display: flex;
  list-style: none;
}

.mobile-list {
  display: flex;
  flex-direction: column;
  list-style: none;

}

.mobile-list li {
  height: 3em;
  display: flex;
  margin-top: 1vh;
  align-items: center;
}

.nav-menu {
  display: none;
  width: 40vw;
}

.desktop-list li {
  margin-left: 4vw;
}

.desktop-list li:hover, .mobile-list li:hover {
  color: rgba(255, 102, 0);
  cursor: pointer;
}

.nav-btn {
  width: 6vh;
}

.close-btn {
  width: 4vh;
}

.nav-btn:hover {
  cursor: pointer;
  fill: rgba(255, 102, 0);
}

.mobile-size {
  display: none;
}

.company-logo:hover {
  cursor: pointer;
}

a {
  text-decoration: none;
  color: black;
}

@media (max-width: 900px) {
  .desktop-size {
    display: none;
  }

  /* .backdrop {
    float: left;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(53, 54, 54, 0.2);
    z-index: 0;
  } */
  
  .navbar img {
    width: 18vw
  }

  .rubbish {
    height: 8vh;
    visibility: hidden;
  }

  .close-btn {
    position: absolute;
    top: 3.5vh;
    right: 4.5vw;
  }

  .mobile-list {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .mobile-list li {
    border-bottom: solid 1px #c7c4c0;
  }

  .nav-menu {
    display: block;
    position: absolute;
    top: 0;
    width: 50vw;
    right: -52vw;
    height: 100%;
    background-color: white;
    box-shadow: 0 0 8px black;
  }

  .nav {
    overflow: hidden;
  }

  .mobile-size {
    display: block;
  }
}