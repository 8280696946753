.contact-us {
  display: flex;
  flex-direction: column;
}

.contact-us-content {
  display: flex;
  justify-content: space-between;
}

.contact-us h1 {
  text-align: center;
  font-family: 'Outfit', sans-serif;
}

.contact-us .form-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-us .form-side input, .contact-us .form-side textarea {
  width: 30vw;
  margin-top: 2vh;
  border-radius: 15px;
  border: 2px solid rgba(255, 102, 0, 0.6);
  padding: 1.1vw;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Red Hat Display', sans-serif;
}

.contact-us .form-side .email {
  height: 5vh;
}

.contact-us .form-side .comment {
  height: 25vh;
}

.contact-us .form-side button {
  float: right;
  background-color: white;
  border: 2px solid rgba(255, 102, 0, 0.6);
  width: 15vh; 
  height: 5vw;
  box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: 'Outfit', sans-serif;
  color: rgba(255, 102, 0, 1);
  border-radius: 5px;
  margin-top: 2vh;
  font-size: 1.1em;
}

.contact-us .form-side button:hover {
  cursor: pointer;
}

.contact-us .image-side {
  display: flex;
  align-items: center;
}

.contact-us-image {
  width: 45vw;
}

@media (max-width: 900px) {
  .contact-us-content {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .contact-us .form-side input, .contact-us .form-side textarea {
    width: 80vw;
  }

  .contact-us .form-side button {
    height: 8vh;
  }

}