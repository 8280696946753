body {
  font-family: 'Red Hat Display', sans-serif;
}

.container {
  margin-left: 5%;
  margin-right: 5%;
}

.active {
  color: rgba(255, 102, 0, 1);
}