.footer {
  margin-top: 15vh;
}

.footer .background {
  width: 100%;
  padding-bottom: 2vh;
  background-color: rgba(255, 102, 0, 1);
}

.footer .content {
  display: flex;
  justify-content: space-between;
}

.footer .image-side {
  display: flex;
  align-items: center;
}

.footer-logo {
  width: 20vw;
}

.footer .text-side {
  display: flex;
  color: white;
  
}

.footer h3 {
  font-family: 'Outfit', sans-serif;
}

.footer li {
  margin-top: 1.5vh;
  list-style: none;
  color: aliceblue;
}

.footer li:hover {
  cursor: pointer;
}

.footer-address {
  display: flex;
  flex-direction: row-reverse;
  color: white;
  text-align: right;
  margin: 2vh 5% 0 0;
}

@media (max-width: 900px) {
  .footer .content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
  }

  
}