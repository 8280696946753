.hero {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 5vh;
}

.left-side {
  display: flex;
  align-items: center;
  font-size: 3em;
  width: 50%;
  font-weight: bold;
}

.left-side span{
  font-weight: lighter;
}

.positive {
  color: rgba(255, 102, 0, 1);
  font-family: 'Bona Nova', serif;
  font-weight: bold;
  font-size: 1.3em;
}

.hero-image {
  width: 45vw;
}

@media (max-width: 900px) {

  .hero {
    flex-direction: column-reverse;
    align-items: center;
  }

  .hero div {
    margin-top: 3vh;
  }

  .left-side {
    width: 80%;
    font-size: 2.3em;
  }

  .hero-image {
    width: 75vw;
  }
}