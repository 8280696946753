.computer-services-hero span {
  font-weight: bold;
  font-size: 1em;
  color: rgba(255, 102, 0, 1);
}

.computer-services-hero {
  margin-bottom: 5vh;
}

.computer-services-content h1 {
  text-align: center;
}

.computer-services-content .banner img {
  width: 98vw;
  margin-top: 5vh;
  margin-bottom: 5vh;
}

.computer-services-content h1 {
  font-family: 'Outfit', sans-serif;
}

.want-to-know-more {
  margin-top: 10vh;
}

.computer-services-content .contact-us {
  display: inline;
  color: rgba(255, 102, 0, 1);
}

.computer-services-content .contact-us:hover {
  cursor: pointer;
}

@media (max-width: 900px) {
  .computer-services-hero .left-side {
    display: flex;
    justify-content: center;
  }

  .computer-services-hero span {
    font-size: 1em;
    text-align: center;
  }

  .computer-services-hero .hero-image {
    width: 75vw;
  }
}